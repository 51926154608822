import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
/*const Home = () => import('../views/home/Home');
const Category = () => import('../views/category/Category');
const Profile = () => import('../views/profile/Profile');
const ShopCart = () => import('../views/shopcart/ShopCart');

const Register = () => import('../views/profile/Register');
const Login = () => import('../views/profile/Login');
const Collect = () => import('../views/collect/Collect');

const Address = () => import('../views/profile/Address');
const PayTest = () => import('../views/paytest/PayTest');
const AddressEdit = () => import('../views/profile/AddressEdit');
const Order = () => import('../views/order/Order');
const OrderDetail = () => import('../views/order/OrderDetail');
const CreateOrder = () => import('../views/order/CreateOrder');
const About = () => import('../views/about/About');*/



const Detail1 = () => import('../views/detail/Detail1');
const Detail2 = () => import('../views/detail/Detail2');
const Detail3 = () => import('../views/detail/Detail3');
const Detail4 = () => import('../views/detail/Detail4');
const Detail5 = () => import('../views/detail/Detail5');
const Detail6 = () => import('../views/detail/Detail6');

import store from '../store';
import {Notify, Toast} from "vant";

const routes = [
  /*{
    path: '/',
    alias:['/','/home'],
    name: 'Home',
    component: Home,
    meta:{
      title:'图书兄弟',
      keepAlive:true,
    }
  },

  {
    path: '/category',
    name: 'Category',
    component: Category,
    meta:{
      title:'图书兄弟-商品分类',
      keepAlive:true,
    }
  },
  {
    path: '/shopcart',
    name: 'ShopCart',
    component: ShopCart,
    meta:{
      title:'图书兄弟-购物车',
      isAuthRequired:true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta:{
      title:'图书兄弟-个人中心',
      isAuthRequired:true
    }
  },
  {
    path: '/collect',
    name: 'Collect',
    component: Collect,
    meta:{
      title:'我的收藏',
      isAuthRequired:true
    }
  },*/

  {
    path: '/detail1',
    name: 'Detail1',
    component: Detail1,
    meta:{
      title:'全国微留学-澳洲站',
      keepAlive:true,
    }
  },
  {
    path: '/detail2',
    name: 'Detail2',
    component: Detail2,
    meta:{
      title:'全国微留学-美国加州',
      keepAlive:true,
    }
  },
  {
    path: '/detail3',
    name: 'Detail3',
    component: Detail3,
    meta:{
      title:'全国微留学-新加坡',
      keepAlive:true,
    }
  },
  {
    path: '/detail4',
    name: 'Detail4',
    component: Detail4,
    meta:{
      title:'全国名校研学-新加坡',
      keepAlive:true,
    }
  },
  {
    path: '/detail5',
    name: 'Detail5',
    component: Detail5,
    meta:{
      title:'全国微留学-新西兰',
      keepAlive:true,
    }
  },
  {
    path: '/detail6',
    name: 'Detail6',
    component: Detail6,
    meta:{
      title:'全国微留学-英国站',
      keepAlive:true,
    }
  },



]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
  history:createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next)=>{

  next();

  document.title = to.meta.title

})

export default router
